import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";

import { useRequest } from "../../hooks";
import { updateUser, addUser } from "../../api";

import Table from "../../components/organisms/Table.jsx";
import TableHead from "../../components/molecules/TableHead.jsx";
import TableBody from "../../components/molecules/TableBody";
import TableRow from "../../components/molecules/TableRow";
import TableCell from "../../components/atoms/TableCell";

import Button from "../../components/atoms/Button";
import ModalPortal from "../../components/organisms/ModalPortal.jsx";
import Modal from "../../components/organisms/Modal";
import { ReactComponent as EditIcon } from "../../assets/icnEdit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icnDelete.svg";
import AddUserModal from "../../components/organisms/AddUserModal";
import EditUserModal from "../../components/organisms/EditUserModal";
import DeleteUserModal from "../../components/organisms/DeleteUserModal";
import "./Member.scss";

// TODO 테이블 데이터 렌더 로직 분리 (권장)
// TODO 업데이트 된 컴포넌트와 디자인 시안 비교 후, 변경 사항 반영
const AdminMember = () => {
  const [result, send] = useRequest();
  const [modal, setModal] = useState(null);
  const categories = ["Name", "Email", "", ""];

  const sendGetUsersRequest = () => {
    const request = {
      method: "GET",
      url: "/users",
    };
    send(request);
  };

  const sendAddRequest = async (data) => {
    try {
      const { status } = await addUser(data);
      if (status === 201) {
        alert('임시비밀번호: hutom1234 \n회원가입이 완료되었습니다. \n가입된 회원에게 직접 알려주시길 바랍니다.');
        sendGetUsersRequest();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const sendEditRequest = async (id, data) => {
    try {
      const { status } = await updateUser(id, data);
      if (status === 200) {
        sendGetUsersRequest();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const sendDeleteRequest = async (id) => {
    try {
      const { status } = await updateUser(id, { isActive: false });
      if (status === 200) {
        sendGetUsersRequest();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const getInfo = (i) => result.data[i];

  const closeModal = () => setModal(null);

  const onClickAdd = () => {
    setModal(
      <AddUserModal
        actions={{ onCancel: closeModal, onSubmitAdd: sendAddRequest }}
        closeModal={closeModal}
      />
    );
  };

  const onClickEdit = (event, i) => {
    event.stopPropagation();
    setModal(
      <EditUserModal
        initialUser={getInfo(i)}
        actions={{ onCancel: closeModal, onSubmitEdit: sendEditRequest }}
        closeModal={closeModal}
      />
    );
  };

  const onClickDelete = (event, i) => {
    event.stopPropagation();
    const id = getInfo(i)._id;
    setModal(
      <DeleteUserModal
        actions={{ onYes: () => sendDeleteRequest(id), onNo: closeModal }}
        closeModal={closeModal}
      />
    );
  };

  const getRows = (data) =>
    data.reduce((acc, curr, i) => {
      const { name, email, isActive } = curr;
      if (isActive) {
        acc.push([
          name,
          email,
          <EditIcon
            onClick={(event) => onClickEdit(event, i)}
            style={{ cursor: "pointer", height: "20px" }}
          />,
          <DeleteIcon
            onClick={(event) => onClickDelete(event, i)}
            style={{ cursor: "pointer", height: "20px" }}
          />,
        ]);
      }
      return acc;
    }, []);

  const rows = useMemo(() => {
    if (result?.data) return getRows(result.data);
  }, [result]);

  useEffect(() => {
    sendGetUsersRequest();
  }, []);

  useEffect(() => {
    setModal(null);
  }, [result]);

  return (
    <>
      <div className="admin-member-page">
        <div className="admin-member-page-div">
          <p className="admin-member-page-p">Members</p>
          <StyledButton label="Add" onClick={onClickAdd} />
        </div>
        <div className="admin-member-page-table">
          {result?.data && (
            <TableWrapper>
              <TableHead>
                <TableRow>
                  {categories.map((cat, i) => (
                    <TableCell
                      width={i === 1 ? "100%" : "initial"}
                      compact={i > 1}
                    >
                      {cat}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow>
                    {row.map((data, i) => (
                      <TableCell
                        width={i === 1 ? "100%" : "initial"}
                        compact={i > 1}
                      >
                        {data}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </TableWrapper>
          )}
        </div>
      </div>
      {modal && (
        <ModalPortal>
          <Modal onClose={closeModal}>{modal}</Modal>
        </ModalPortal>
      )}
    </>
  );
};

const TableWrapper = styled(Table)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  width: 180px;
  border-radius: 43px;
  align-items: center;
`;

export default AdminMember;
